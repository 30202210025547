import React from 'react'
import { Container, Heading, IconContainer, Paragraph } from './Backups'
import { useIsDarkMode } from 'hooks/use-media-query';
import { AuthIcon, buttonIcons } from 'images/icons/auth-homepage-icons';
import DownloadButton from './DownloadButton';
import styled from 'styled-components';

interface IProp {
    sectionIcon : AuthIcon;
}

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 2%;
    margin-inline: 5%;
`;

export default function DownloadSection(props : IProp) {
    const icon = useIsDarkMode()
    ? props.sectionIcon.iconDark
    : props.sectionIcon.iconLight;
  return (
    <Container id="download-auth">
        <IconContainer>{icon}</IconContainer>
        <Heading>Download Auth</Heading>
        <Paragraph>Available everywhere</Paragraph>
        <ButtonContainer>
            {buttonIcons.map(button => {
                return  <DownloadButton text={button.text} icon={button.icon} link={button.link}/>
            })}
        </ButtonContainer>
    </Container>
  )
}
