import React from "react";
import { Container } from "react-bootstrap";
import { Heading, IconContainer, Paragraph } from "./Backups";
import {
    AuthIcon,
    buttonHoverBoxShadowLight,
    openSourceIcons,
} from "images/icons/auth-homepage-icons";
import styled from "styled-components";
import { CardStyle } from "./Features1";
import { useIsDarkMode } from "hooks/use-media-query";
import { CardTitle } from "./Features2";
import { buttonHoverBoxShadowDark } from "images/icons/auth-homepage-icons";
import { StarAnimation } from "./DownloadButton";
import { Link } from "gatsby";

interface IProps {
    sectionIcon: AuthIcon;
}

const CardContainer = styled(CardStyle)`
    position: relative;
    aspect-ratio: 2.05;
    width: 681px;
    border-radius: 3% / 5%;
    @media (max-width: 992px) {
        width: 500px;
    }
    @media (max-width: 576px) {
        width: 410px;
    }
    @media (max-width: 440px) {
        width: 330px;
    }
    @media (max-width: 330px) {
        width: 250px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-inline: 8% 2.5%;
    margin-top: 5%;
    width: 90.5%;
    height: 15%;
`;

const SvgContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    svg {
        height: 45px;
        width: 45px;
        @media (max-width: 992px) {
            height: 32px;
            width: 32px;
        }
        @media (max-width: 576px) {
            height: 26px;
            width: 26px;
        }
        @media (max-width: 440px) {
            height: 21px;
            width: 21px;
        }
        @media (max-width: 330px) {
            height: 15px;
            width: 15px;
        }
    }
`;

const Text = styled(CardTitle)`
    margin: 0;
    padding: 0;
    font-weight: 500;
    @media (max-width: 768px) {
        font-size: 14px;
    }
    @media (max-width: 576px) {
        font-size: 16px;
    }
    @media (max-width: 440px) {
        font-size: 12px;
    }
    @media (max-width: 330px) {
        font-size: 10px;
    }
`;

const Title = styled(Text)`
    margin-left: 8%;
    scale: 1.5;
`;

const StarAnimationContainer = styled.div`
    position: absolute;
    height: 95%;
    width: 100%;
    overflow: hidden;
    top: 0%;
`;

export default function OpenSource(props: IProps) {
    const isDarkMode = useIsDarkMode();
    const icon = isDarkMode
        ? props.sectionIcon.iconDark
        : props.sectionIcon.iconLight;
    const githubIcon = isDarkMode
        ? openSourceIcons["github"].iconDark
        : openSourceIcons["github"].iconLight;
    const repoIcon = isDarkMode
        ? openSourceIcons["repo"].iconDark
        : openSourceIcons["repo"].iconLight;
    const starIcon = isDarkMode
        ? openSourceIcons["star"].iconDark
        : openSourceIcons["star"].iconLight;
    const scaleIcon = isDarkMode
        ? openSourceIcons["scale"].iconDark
        : openSourceIcons["scale"].iconLight;
    const publicIcon = isDarkMode
        ? openSourceIcons["public"].iconDark
        : openSourceIcons["public"].iconLight;

    const [isHovered, setIsHovered] = React.useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <Container id="pricing">
            <IconContainer>{icon}</IconContainer>
            <Heading>Free and open source</Heading>
            <Paragraph>
                Ente is fully open source. You can export and import your data. No lockins.
            </Paragraph>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBlock: "5%",
                }}
            >
                <Link
                    to="https://github.com/ente-io/ente"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    <CardContainer
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Row
                            style={{
                                justifyContent: "flex-end",
                                marginTop: "1.5%",
                            }}
                        >
                            <SvgContainer
                                style={{
                                    justifyContent: "flex-end",
                                    alignItems: "start",
                                }}
                            >
                                {githubIcon}
                            </SvgContainer>
                        </Row>

                        <Row style={{ marginTop: "0%" }}>
                            <SvgContainer>{repoIcon}</SvgContainer>
                            <Title>
                                ente-io /&nbsp;
                                <span style={{ fontWeight: "600" }}>ente</span>
                            </Title>
                            <SvgContainer
                                style={{ scale: "1.5", marginLeft: "10%" }}
                            >
                                {publicIcon}
                            </SvgContainer>
                        </Row>

                        <Row>
                            <Text>
                                Fully open source, end-to-end encrypted cloud
                            </Text>
                        </Row>

                        <Row>
                            <SvgContainer>{starIcon}</SvgContainer>
                            <Text style={{ marginLeft: "2%" }}>10k+ stars</Text>
                            <SvgContainer style={{ marginLeft: "12%" }}>
                                {scaleIcon}
                            </SvgContainer>
                            <Text style={{ marginLeft: "2%" }}>AGPL-3.0</Text>
                        </Row>
                        {isHovered && (
                            <StarAnimationContainer>
                                {isDarkMode ? (
                                    <StarAnimation
                                        boxShadow={buttonHoverBoxShadowDark}
                                    />
                                ) : (
                                    <StarAnimation
                                        boxShadow={buttonHoverBoxShadowLight}
                                    />
                                )}
                            </StarAnimationContainer>
                        )}
                    </CardContainer>
                </Link>
            </div>
        </Container>
    );
}
