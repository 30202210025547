import React from "react";
import Layout from "components/Layout";
import ExperienceEnte from "components/homepageSections/ExperienceEnte";
import HeroSection from "components/homepageSections/HeroSection";
import { PageProps, graphql } from "gatsby";
import { FluidImageData } from "types";
import "styles/landing.css";
import Backups from "components/authHomepageSections/Backups";
import Platform from "components/authHomepageSections/Platform";
import { authIcons } from "images/icons/auth-homepage-icons";
import DownloadSection from "components/authHomepageSections/DownloadSection";
import Features1 from "components/authHomepageSections/Features1";
import Features2 from "components/authHomepageSections/Features2";
import OpenSource from "components/authHomepageSections/OpenSource";
interface IPageData {
    authHomePage: FluidImageData;
    authHomePageDark: FluidImageData;
    connector1: FluidImageData;
    connector2: FluidImageData;
    connector1Dark: FluidImageData;
    connector2Dark: FluidImageData;
    authIpadHome: FluidImageData;
    authIpadHomeDark: FluidImageData;
    authLaptopHome: FluidImageData;
    authLaptopHomeDark: FluidImageData;
    authOfflineFeature: FluidImageData;
    authOfflineFeatureDark: FluidImageData;
    authHomeScreenBlank: FluidImageData;
    authHomeScreenBlankDark: FluidImageData;
    authCategory: FluidImageData;
    authCategoryDark: FluidImageData;
    searchBar: FluidImageData;
    searchBarDark: FluidImageData;
    authQRPhone: FluidImageData;
    authQRPhoneDark: FluidImageData;
    searchBar2: FluidImageData;
    searchBar2Dark: FluidImageData;
    authApps: FluidImageData;
}

interface HeroSectionProps {
    headingText: string;
    headingHighlightText: string;
    paragraphText: string;
    buttonText: string;
    backdropBackground: string;
    duckyShieldColor: string;
}

interface ExperienceEnteProps {
    background: string;
    heading: string;
    subheading: string;
    icon: string;
}

const Index: React.FC<PageProps<IPageData>> = ({
    path,
    data: {
        authHomePage,
        authHomePageDark,
        connector1,
        connector2,
        connector1Dark,
        connector2Dark,
        authIpadHome,
        authIpadHomeDark,
        authLaptopHome,
        authLaptopHomeDark,
        authOfflineFeature,
        authOfflineFeatureDark,
        authHomeScreenBlank,
        authHomeScreenBlankDark,
        authCategory,
        authCategoryDark,
        searchBar,
        searchBarDark,
        authQRPhone,
        authQRPhoneDark,
        searchBar2,
        searchBar2Dark,
        authApps,
    },
}) => {
    const heroSectionProps: HeroSectionProps = {
        headingText: "",
        headingHighlightText: "Ente Auth",
        paragraphText:
            "Open source 2FA authenticator, with end-to-end encrypted backups",
        buttonText: "Setup Auth",
        backdropBackground: `linear-gradient(315deg, #531dab, #8330E3)`,
        duckyShieldColor: "#a117e6",
    };

    const experienceEnteProps: ExperienceEnteProps = {
        background: "linear-gradient(315deg, #531dab, #782bd6)",
        heading: "Protect your accounts",
        subheading: "Scan the QR code to get started",
        icon: "auth",
    };

    return (
        <Layout {...{ path, shouldShowDownloads: false, isPageAuth: true }}>
            <HeroSection
                images={[authHomePage, authHomePageDark]}
                {...heroSectionProps}
            />
            <Backups
                images={[
                    connector1,
                    connector2,
                    connector1Dark,
                    connector2Dark,
                    authHomePage,
                    authHomePageDark,
                    authIpadHome,
                    authIpadHomeDark,
                ]}
                sectionIcon={authIcons["backup"]}
            />
            <Platform
                images={[
                    authLaptopHome,
                    authLaptopHomeDark,
                    authHomePage,
                    authHomePageDark,
                ]}
                sectionIcon={authIcons["crossPlatform"]}
            />
            <Features1
                images={[
                    authOfflineFeature,
                    authOfflineFeatureDark,
                    searchBar,
                    searchBarDark,
                    authHomeScreenBlank,
                    authHomeScreenBlankDark,
                    authCategory,
                    authCategoryDark,
                ]}
                sectionIcon={authIcons["thunder"]}
            />
            <DownloadSection sectionIcon={authIcons["download"]} />
            <Features2
                images={[
                    authQRPhone,
                    authQRPhoneDark,
                    searchBar2,
                    searchBar2Dark,
                    authApps,
                ]}
                sectionIcon={authIcons["import"]}
            />
            <ExperienceEnte {...experienceEnteProps} />
            <OpenSource sectionIcon={authIcons["openSource"]} />
        </Layout>
    );
};

export default Index;

export const pageQuery = graphql`
    query HeaderImageQuery {
        authHomePage: imageSharp(
            fluid: { originalName: { eq: "auth-home-screen.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authHomePageDark: imageSharp(
            fluid: { originalName: { eq: "auth-home-screen-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        connector1: imageSharp(
            fluid: { originalName: { eq: "auth-backups-connector1.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        connector2: imageSharp(
            fluid: { originalName: { eq: "auth-backups-connector2.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        connector1Dark: imageSharp(
            fluid: { originalName: { eq: "auth-backups-connector1-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        connector2Dark: imageSharp(
            fluid: { originalName: { eq: "auth-backups-connector2-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authIpadHome: imageSharp(
            fluid: { originalName: { eq: "auth-ipad-home.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authIpadHomeDark: imageSharp(
            fluid: { originalName: { eq: "auth-ipad-home-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authLaptopHome: imageSharp(
            fluid: { originalName: { eq: "auth-laptop-home.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authLaptopHomeDark: imageSharp(
            fluid: { originalName: { eq: "auth-laptop-home-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authOfflineFeature: imageSharp(
            fluid: { originalName: { eq: "auth-offline-feature.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authOfflineFeatureDark: imageSharp(
            fluid: { originalName: { eq: "auth-offline-feature-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authHomeScreenBlank: imageSharp(
            fluid: { originalName: { eq: "auth-home-screen-blank.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authHomeScreenBlankDark: imageSharp(
            fluid: { originalName: { eq: "auth-home-screen-blank-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authCategory: imageSharp(
            fluid: { originalName: { eq: "auth-category.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authCategoryDark: imageSharp(
            fluid: { originalName: { eq: "auth-category-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        searchBar: imageSharp(
            fluid: { originalName: { eq: "search-bar.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        searchBarDark: imageSharp(
            fluid: { originalName: { eq: "search-bar-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authQRPhone: imageSharp(
            fluid: { originalName: { eq: "auth-phone-qr.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authQRPhoneDark: imageSharp(
            fluid: { originalName: { eq: "auth-phone-qr-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        searchBar2: imageSharp(
            fluid: { originalName: { eq: "search-bar2.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        searchBar2Dark: imageSharp(
            fluid: { originalName: { eq: "search-bar2-dark.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authApps: imageSharp(
            fluid: { originalName: { eq: "auth-2FA-apps.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
    }
`;
